@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.font-roboto {
  font-family: 'Roboto', sans-serif;
}

body {
  background-color: white;
  font-family: 'Roboto', sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}